import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // Insert section
    addNewFormul(ctx, formul) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`addNewFormul`, formul)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchFormulsTable(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getFormulsTable')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateFormul(ctx, formul) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateFormul/${formul.id}`, formul)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteFormul(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteFormul/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkFormulTitle(ctx, title) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFormulTitle`, title)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    //********** */

    //Input section
    fetchAllInputs(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchAllInputs')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchInputsData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchInputsData')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createCalculateVariables(ctx, variables) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`addNewCalculateVariables`, variables)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateCalculateVariables(ctx, variables) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateCalculateVariables/${variables.id}`, variables)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchInput(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getInput/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCalculateInput(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getCalculateInput/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteInput(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteInput/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //********** */


    // output section

    fetchCalculatedData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchCalculatedDatas')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchFormuls(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchFormulasToCalculate')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    //*********** */


    //table section

    createTable(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewTable`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addTableValue(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`addTableValue`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTables(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getTables')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchTableValues(ctx, name) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getTableValues/${name}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkTableName(ctx, tableName) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTableName`, tableName)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    isTableUsed(ctx, tableName) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`isTableUsed`, tableName)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSelectedTable(ctx, name) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getSelectedTable/${name}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteTable(ctx, name) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteTable/${name}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTableValue(ctx, variables) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateTableValue/${variables.id}`, variables)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchTablesWithColumns(ctx,) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getTablesWithColumns`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //************** */
  },
};
